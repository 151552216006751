import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, patch, post } from '../../apiService';

export const applyJob = createAsyncThunk(
    'applyJob',
    ({ payload }) =>
        new Promise((resolve, reject) => {
            resolve(post(`application`, payload));
        }),
);

export const checkAllreadyApplied = createAsyncThunk(
    'checkAllreadyApplied',
    ({ id }) =>
        new Promise((resolve, reject) => {
            resolve(get(`application/checkapplicant/${id}`));
        }),
);

export const getAllApplications = createAsyncThunk(
    'getAllApplications',
    (payload) =>
        new Promise((resolve, reject) => {
            let url = 'application?'
            if (payload) {
                for (let keys in payload) {
                    url += `${keys}=${payload[keys]}`
                }
            }
            resolve(get(url));
        }),
);

export const hire = createAsyncThunk(
    'hire',
    (payload) =>
        new Promise((resolve, reject) => {
            resolve(patch(`application/${payload.id}`, payload.data));
        }),
);

export const cancelJob = createAsyncThunk(
    'cancelJob',
    ({ job, companion }) =>
      new Promise((resolve, reject) => {
        resolve(patch(`application/canceljob/${companion}`, { id: job }));
      }),
  );